import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../App";
import Login from "../components/auth/Login";

function LoginPage({ handleLogin }) {
  const navigate = useNavigate();
  const loggedInStatus = useContext(Context);

  React.useEffect(() => {
    if (loggedInStatus === "LOGGED_IN") {
      navigate(`/maintenances`);
    }
  }, []);

  const handleSuccessfulAuth = (data, accessToken) => {
    // update parent component
    data.accessToken = accessToken;
    handleLogin(data);
    // navigate to dashboard
    navigate("/maintenances");
  };

  return (
    <div>
      {/* <h1>LOGIN: {loggedInStatus}</h1> */}
      <Login handleSuccessfulAuth={handleSuccessfulAuth} />
    </div>
  );
}

export default LoginPage;
