import React from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";

function CustomMultiSelect({ label, menuItems, selection, handleChange }) {

  return (
    <FormControl sx={{ m: 1, minWidth: 120, marginLeft: "4%" }} size="small">
      <InputLabel id="demo-select-small-label">{label}</InputLabel>
      <Select
        multiple
        label={label}
        value={selection}
        id="select-plants"
        labelId="select-plants"
        onChange={handleChange}
        sx={{ fontSize: "12px" }}
      >
        <MenuItem value="---">
          <em>Nessuna selezione</em>
        </MenuItem>

        {
          menuItems.map((menuItem, index) => {
            return <MenuItem key={index} value={menuItem}>{menuItem}</MenuItem>
          })
        }
      </Select>
    </FormControl>
  );
}

export default CustomMultiSelect;
