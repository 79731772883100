import React from "react";

import { Fab, Button, IconButton } from "@mui/material";

function CustomButton({ option, btnText, btnIcon, onClick }) {
  switch (option) {
    case "filled-red":
      return (
        <Button
          fullWidth
          type="submit"
          disableElevation
          variant="contained"
          startIcon={btnIcon}
          sx={{
            mt: 3,
            mb: 2,
            fontSize: 18,
            color: "#FFF",
            fontWeight: "medium",
            borderRadius: "12px",
            textTransform: "none",
            fontFamily: "Poppins",
            backgroundColor: "#FE0B00",
            ":hover": {
              bgcolor: "white",
              color: "#FE0b00",
              border: "1px solid #fe0b00",
            },
          }}
          onClick={onClick}
        >
          {btnText}
        </Button>
      );
    case "outlined-red":
      return (
        <Button
          fullWidth
          type="submit"
          disableElevation
          variant="outlined"
          startIcon={btnIcon}
          sx={{
            mt: 3,
            mb: 2,
            fontSize: 18,
            color: "#FE0B00",
            fontWeight: "medium",
            borderRadius: "12px",
            textTransform: "none",
            fontFamily: "Poppins",
            backgroundColor: "#FFF",
            ":hover": {
              bgcolor: "FE0B00",
              color: "#fff",
              border: "1px solid #fff",
            },
          }}
          onClick={onClick}
        >
          {btnText}
        </Button>
      );
    case "outlined-red-small":
      return (
        <Button
          fullWidth
          size="small"
          type="submit"
          disableElevation
          variant="outlined"
          startIcon={btnIcon}
          sx={{
            padding: "0 12px",
            fontSize: 14,
            color: "#FE0B00",
            borderRadius: "8px",
            textTransform: "none",
            fontFamily: "Poppins",
            backgroundColor: "#FFF",
            boxShadow: "0px 2px 10px 0px rgba(124, 141, 181, 0.12)",
            ":hover": {
              color: "#fff",
              bgcolor: "#FE0B00",
              border: "1px solid #fff",
            },
          }}
          onClick={onClick}
        >
          {btnText}
        </Button>
      );
    case "outlined-gray":
      return (
        <Button
          fullWidth
          disableElevation
          variant="outlined"
          startIcon={btnIcon}
          sx={{
            mt: 3,
            mb: 2,
            fontSize: 18,
            color: "#6B718B",
            fontWeight: "medium",
            borderRadius: "12px",
            textTransform: "none",
            fontFamily: "Poppins",
            backgroundColor: "#FFF",
            border: "1px solid #6b718b",
            ":hover": {
              color: "#FE0B00",
              border: "1px solid #FE0B00",
            },
          }}
          onClick={onClick}
        >
          {btnText}
        </Button>
      );
    case "refresh":
      return (
        <IconButton
          sx={{
            mt: 3,
            mb: 2,
            fontSize: 18,
            color: "#6B718B",
            fontWeight: "medium",
            borderRadius: "12px",
            textTransform: "none",
            fontFamily: "Poppins",
            backgroundColor: "#FFF",
            ":hover": {
              color: "#FE0B00",
              border: "1px solid #FE0B00",
            },
          }}
          onClick={onClick}
        >
          {btnIcon}
        </IconButton>
      );
    default:
      return (
        <Fab
          size="small"
          color="primary"
          onClick={onClick}
          sx={{ position: "fixed", bottom: "3%", right: "4%" }}
        >
          {btnIcon}
        </Fab>
      );
    // code block
  }
}

export default CustomButton;
