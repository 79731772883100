import React from "react";
import { useParams, useLocation } from "react-router-dom";

import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
//theme
import "./table.css";
import "primereact/resources/themes/viva-light/theme.css";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Stack,
  Divider,
  List,
  ListItem,
  Grid,
  ListItemText,
} from "@mui/material";

import PlantMap from "./PlantMap";
import PlantGraph from "./PlantGraph";
import PlantMaintenances from "./PlantMaintenances";
import CustomButton from "../../../components/shared/CustomButton";

function PlantShow({ route, navigate }) {
  const { id } = useParams();
  const location = useLocation();
  const [backToTopButton, setBackToTopButton] = React.useState(false);

  const lng = location.state.gps_coordinates.split(",")[1].trim().slice(0, -2);
  const lat = location.state.gps_coordinates.split(",")[0].trim().slice(0, -2);

  const tableData = [
    { parameter: "Rotor running", value: location.state.rotor_running },
    // "Last error status": location.state.,
    { parameter: "Current status", value: location.state.current_status },
    // "Last warning": location.state.,
    { parameter: "Current warning", value: location.state.current_warning },
    {
      parameter: "Turbine state (status)",
      value: location.state.turbine_status,
    },
    {
      parameter: "Turbine fault counter - today",
      value: location.state.turbine_fault_counter_today,
    },
    {
      parameter: "Average wind - today",
      value: location.state.average_wind_this_day,
    },
    { parameter: "Rotor speed", value: location.state.rotor_speed },
    {
      parameter: "Energy produced - today",
      value: location.state.energy_produced_today,
    },
    {
      parameter: "Transmission DCE-WT",
      value: location.state.transmission_dce_wt,
    },
    {
      parameter: "Power supply faults - today",
      value: location.state.power_supply_fault_today,
    },
  ];

  const columns = [
    <Column key="1" field="parameter" header="Parametro" sortable></Column>,
    <Column key="2" field="value" header="Valore"></Column>,
  ];

  const header = (
    <h3>Stato impianto: {location.state.rotor_running.toUpperCase()}</h3>
  );

  React.useEffect(() => {
    // back to top button
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  });

  return (
    <Box sx={{ margin: "0 32px" }}>
      <h2 style={{ marginBottom: 0 }}>{location.state.name}</h2>
      <p style={{ marginTop: 0, color: "#7C8DB5" }}>
        {location.state.location}
      </p>

      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {/* PLANT MAP */}
        <Grid item xs={7}>
          <Box
            sx={{
              padding: "4%",
              height: "auto",
              borderRadius: "12px",
              border: "1px solid #e6edff",
            }}
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box>
                <h3 style={{ margin: 0 }}>Posizione impianto</h3>
                <p style={{ marginTop: 0, color: "#7C8DB5" }}>
                  {location.state.location}
                </p>
              </Box>
              <Box>
                <CustomButton
                  btnIcon={null}
                  option="outlined-red-small"
                  btnText="Apri in Google Maps"
                  onClick={() =>
                    window.open("https://maps.google.com?q=" + lat + "," + lng)
                  }
                />
              </Box>
            </Stack>

            <PlantMap lng={lng} lat={lat} />
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Box>
                <p style={{ marginBottom: 0 }}>
                  <b>Latitudine</b>
                </p>
                <p style={{ marginTop: 0, color: "#7C8DB5" }}>
                  {location.state.gps_coordinates.split(",")[0].trim()}
                </p>
              </Box>
              <Box>
                <p style={{ marginBottom: 0 }}>
                  <b>Longitudine</b>
                </p>
                <p style={{ marginTop: 0, color: "#7C8DB5" }}>
                  {location.state.gps_coordinates.split(",")[1].trim()}
                </p>
              </Box>
            </Stack>
          </Box>
        </Grid>

        {/* PLANT PARAMETERS TABLE */}
        <Grid item xs={5}>
          <DataTable
            scrollable
            header={header}
            value={tableData}
            removableSort
            scrollHeight="510px"
            style={{ margin: 0 }}
          >
            {columns}
          </DataTable>
        </Grid>
      </Grid>

      <PlantGraph />
      <PlantMaintenances
        plantId={id}
        contractor={location.state.contractor_id}
      />
      {backToTopButton && (
        <CustomButton
          option=""
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          btnIcon={<KeyboardArrowUpIcon />}
        />
      )}
    </Box>
  );
}

export default PlantShow;
