// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC92KxTC7iZJElqogzF1dOZMvfHWgCObuI",
  authDomain: "memos-b4608.firebaseapp.com",
  projectId: "memos-b4608",
  storageBucket: "memos-b4608.appspot.com",
  messagingSenderId: "1084025421870",
  appId: "1:1084025421870:web:bd97c395018e5589311fbf",
  measurementId: "G-3P7RZXPELE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      // Initialize Firebase Cloud Messaging and get a reference to the service
      const messaging = getMessaging();
      // if (messaging) {
      //   const newSw = navigator.serviceWorker.register(
      //     '/homepage/basename/firebase-messaging-sw.js'
      //   );
      // Add the public key generated from the console here.
      getToken(messaging, {
        vapidKey:
          "BGrB-BV2uxE57hkYoscymKJZnKR1kIVDlbWKj9ud6SKSdjwHaxadyr9Xu0zGt5zHT9Te0fKsf9V64SDPUe4bpRU",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("current token: ", currentToken);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      console.log("You do not have permission");
    }
  });
}

requestPermission();
