import React, { useRef, useEffect, useState } from "react";
import * as maptilersdk from "@maptiler/sdk";
import "@maptiler/sdk/dist/maptiler-sdk.css";

import "./map.css";

function PlantMap({ lng, lat }) {
  const map = useRef(null);
  const [zoom] = useState(14);
  const mapContainer = useRef(null);
  maptilersdk.config.apiKey = "FXCSFw8h62guHwjZUfBG";

  useEffect(() => {
    // stops map from intializing more than once
    if (map.current) return;

    map.current = new maptilersdk.Map({
      container: mapContainer.current,
      style: maptilersdk.MapStyle.STREETS,
      center: [lng, lat],
      zoom: zoom,
    });

    new maptilersdk.Marker({ color: "#FE0B00" })
      .setLngLat([lng, lat])
      .addTo(map.current);
  }, [lng, lat, zoom]);

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
    </div>
  );
}

export default PlantMap;
