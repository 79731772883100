import React from "react";
import { useParams } from "react-router-dom";

function ChecklistShow() {
  const { id } = useParams();

  return <div>ho {id}</div>;
}

export default ChecklistShow;
