import React from "react";
import { Chip } from "@mui/material";

function CustomChip({ text }) {
  let color = "";
  let capitalizedText = text?.charAt(0).toUpperCase() + text?.slice(1);

  switch (capitalizedText) {
    case "Preventiva":
      color = "info";
      break;
    case "Integrativa":
      color = "success";
      break;
    case "Park Venosa ":
      color = "success";
      break;
    case "Park Foiano ":
      color = "warning";
      break;
    case "Park Tricarico ":
      color = "info";
      break;
    case "Park Barile ":
      color = "primary";
      break;
    case "In programma":
      color = "success";
      break;
    case "In corso":
      color = "warning";
      break;
    case "Terminata":
      color = "info";
      break;
    case "Sospesa":
      color = "primary";
      break;
    ///////////
    default:
      color = "warning";
      break;
  }
  return (
    <Chip
      variant="outlined"
      color={color}
      size="small"
      label={capitalizedText}
    />
  );
}

export default CustomChip;
