import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import { Box } from "@mui/material";
import CustomSidebar from "../scenes/global/CustomSidebar";

function Page() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <CustomSidebar />
      <Box
        sx={{
          width: "85%",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

function PrivateRoute({ loggedInStatus }) {
  return loggedInStatus === "LOGGED_IN" ? <Page /> : <Navigate to="/" />;
}

export default PrivateRoute;
