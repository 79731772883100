import React from "react";

import Appbar from "../../scenes/global/Appbar";

function IndexMainContent({
  title,
  username,
  subtitle,
  avatarURL,
  handleLogout,
  pageComponent,
}) {
  return (
    <>
      <Appbar
        title={title}
        username={username}
        subTitle={subtitle}
        avatarURL={avatarURL}
        handleLogout={handleLogout}
      />
      {pageComponent}
    </>
  );
}

export default IndexMainContent;
