import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import logo from "../../assets/logo.png";

import { Box, IconButton } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import WindPowerOutlinedIcon from "@mui/icons-material/WindPowerOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const NavItem = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      icon={icon}
      active={selected === title}
      onClick={() => setSelected(title)}
      component={<Link to={to} />}
    >
      {title}
    </MenuItem>
  );
};

const CustomSidebar = () => {
  const [selected, setSelected] = useState("Alerts");
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Box
      sx={{
        marginTop: "1.7%",
        "& .css-dip3t8": {
          backgroundColor: "#fff",
        },
        "& .ps-menu-button:hover": {
          color: "#FE0B00 !important",
          backgroundColor: "#FFF !important",
        },
      }}
    >
      <Sidebar collapsed={isCollapsed}>
        <Menu
          iconShape="square"
          menuItemStyles={{
            button: ({ level, active }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: active ? "#FE0B00" : "#6B718B",
                  borderRight: active ? "2px solid #FE0B00" : undefined,
                };
            },
          }}
        >
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
            }}
          >
            {!isCollapsed && (
              <Box
                mb="24px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box component="img" src={logo} sx={{ width: "65%" }}></Box>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          <Box mb="25px">
            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <SubMenu
                defaultOpen
                label="Alerts"
                icon={<ErrorOutlineOutlinedIcon />}
              >
                <NavItem
                  title="Alert attivi"
                  to="/alerts"
                  selected={selected}
                  setSelected={setSelected}
                />
                <NavItem
                  title="Alert archiviati"
                  to="/archived-alerts"
                  selected={selected}
                  setSelected={setSelected}
                />
              </SubMenu>

              <SubMenu
                defaultOpen
                label="Manutenzioni"
                icon={<BuildOutlinedIcon />}
              >
                <NavItem
                  title="Manutenzioni spot"
                  to="/maintenances"
                  selected={selected}
                  setSelected={setSelected}
                />
                <NavItem
                  title="Manutenzioni periodiche"
                  to="/periodic-maintenances"
                  selected={selected}
                  setSelected={setSelected}
                />
              </SubMenu>
              <NavItem
                title="Impianti"
                to="/plants"
                icon={<WindPowerOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <NavItem
                title="Appalti"
                to="/contractors"
                icon={<BadgeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <NavItem
                title="Grafici"
                to="/graphs"
                icon={<QueryStatsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default CustomSidebar;
