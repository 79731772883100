import React from "react";
import { Box, Stack } from "@mui/material";

import red from "../../assets/red-dot.png";
import gray from "../../assets/grayDot.png";
import green from "../../assets/green-dot.png";
import orange from "../../assets/orange-dot.png";

function SeverityTag({ text }) {
  let img = "";
  let capitalizedText =
    text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

  switch (capitalizedText) {
    case "High":
      capitalizedText = "Alta";
      img = red;
      break;
    case "Alta":
      img = red;
      break;
    case "Media":
      img = orange;
      break;
    case "Low":
      capitalizedText = "Bassa";
      img = green;
      break;
    case "Bassa":
      img = green;
      break;
    case "Emergency":
      img = red;
      break;
    case "Stop":
      img = orange;
      break;
    case "Run":
      img = green;
      break;
    default:
      img = gray;
      break;
  }

  return (
    <Box
      sx={{
        padding: "2px 6px",
        color: "#232020",
        fontSize: "12px",
        maxWidth: "100px",
        background: "#fff",
        borderRadius: "8px",
        fontWeight: "medium",
        border: "1px solid rgba(124, 141, 181, 0.12)",
        boxShadow: "0px 2px 10px 0px rgba(124, 141, 181, 0.12)",
      }}
    >
      <Stack direction="row" justifyContent="space-around" alignItems="center">
        <Box
          src={img}
          component="img"
          alt="severity color"
          sx={{ width: "8px", marginRight: 2 }}
        ></Box>
        <span className="text-base">{capitalizedText}</span>
      </Stack>
    </Box>
  );
}

export default SeverityTag;
