import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Stack,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import PlantsDataTable from "./PlantsDataTable";
import CustomButton from "../../../components/shared/CustomButton";

function PlantsIndex() {
  const query = useRef();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [backToTopButton, setBackToTopButton] = useState(false);

  const API_URL = `${process.env.REACT_APP_API_BASE_URL}/plants/get-plants`;
  const BearerToken = JSON.parse(
    localStorage.getItem("currentUser")
  ).accessToken;

  async function getAPIData() {
    return await axios
      .get(API_URL, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${BearerToken}`,
        },
      })
      .then((response) => response.data);
  }

  useEffect(() => {
    let mounted = true;
    // get data from API
    getAPIData()
      .then((items) => {
        if (mounted) {
          setRecords(items.data);
        }
      })
      .finally(() => {
        setLoading(false);
        // back to top button
        window.addEventListener("scroll", () => {
          if (window.scrollY > 50) {
            setBackToTopButton(true);
          } else {
            setBackToTopButton(false);
          }
        });
      });

    return () => (mounted = false);
  }, []);

  // query the db for a search
  const filteredRecords = async (query) => {
    const queryUrl = `${API_URL}?query=${query}`;

    await axios
      .get(queryUrl, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${BearerToken}`,
        },
      })
      .then((response) => {
        setRecords(response.data.data);
      });
  };

  // update component after editing record
  const handleEdit = () => {
    getAPIData(API_URL).then((items) => setRecords(items.data));
    // display success alert
    setAlertContent("Impianto modificato.");
    setAlert(true);
  };

  return (
    <Box>
      <Stack
        useFlexGap
        spacing={40}
        margin="0 22px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* SEARCHBAR */}
        <TextField
          type="search"
          inputRef={query}
          placeholder="Cerca"
          InputProps={{
            style: {
              width: "150%",
              height: "57px",
              maring: "11px 0",
              borderRadius: "12px",
              border: "1px solid #E6EDFF",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          onChange={() => filteredRecords(query.current.value)}
        />
        <Stack direction="row" gap={2}>
          {/* ADD ITEM BTN */}
          <Button
            fullWidth
            disabled
            disableElevation
            variant="contained"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            sx={{
              mt: 3,
              mb: 2,
              fontSize: 18,
              fontWeight: "medium",
              borderRadius: "12px",
              textTransform: "none",
              fontFamily: "Poppins",
            }}
          >
            Aggiungi
          </Button>
        </Stack>
      </Stack>
      {/* DATA TABLE */}
      <div>
        {loading ? (
          <CircularProgress
            color="primary"
            sx={{ marginLeft: "50%", marginTop: "10%" }}
          />
        ) : (
          <PlantsDataTable data={records} handleEdit={handleEdit} />
        )}
      </div>
      {backToTopButton && (
        <CustomButton
          option=""
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          btnIcon={<KeyboardArrowUpIcon />}
        />
      )}
    </Box>
  );
}

export default PlantsIndex;
