import React from "react";

import {
  Box,
  Tab,
  Tabs,
  Stack,
  Snackbar,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import CustomButton from "../../../components/shared/CustomButton";

function ChecklistsIndex() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      {/* <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={() => setAlert(false)}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={() => setAlert(false)}
        >
          {alertContent}
        </Alert>
      </Snackbar> */}
      <Stack
        useFlexGap
        spacing={40}
        margin="0 22px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* SEARCHBAR */}
        {/* <TextField
          type="search"
          inputRef={query}
          placeholder="Cerca"
          InputProps={{
            style: {
              width: "150%",
              height: "57px",
              maring: "11px 0",
              borderRadius: "12px",
              border: "1px solid #E6EDFF",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          onChange={() => filteredRecords(query.current.value)}
        /> */}
        {/* ADD ITEM BTN */}
        <div>{/* <AddCompanyForm handleCreate={handleCreate} /> */}</div>
      </Stack>
      {/* TABS */}
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="secondary tabs example"
      >
        <Tab value="1" label="Checklist complete" />
        <Tab value="2" label="Checklist incomplete" />
      </Tabs>
    </Box>
  );
}

export default ChecklistsIndex;
