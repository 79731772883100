import React from "react";
import axios from "axios";
import { useParams, useLocation, Link } from "react-router-dom";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Grid,
  Stack,
  List,
  Divider,
  ListItem,
  CircularProgress,
} from "@mui/material";

import PlantMap from "../Plants/PlantMap";
import SeverityTag from "../../../components/shared/SeverityTag";
import CustomButton from "../../../components/shared/CustomButton";

function AlertsShow({ route, navigate }) {
  const { id } = useParams();
  const location = useLocation();
  const [backToTopButton, setBackToTopButton] = React.useState(false);
  const [maintenancePlant, setMaintenancePlant] = React.useState(null);

  const checklistStepsToAdd = [
    {
      title: "Controllo sistemi di sicurezza",
      subtitle: "",
    },
    {
      title: "Ispezione visiva",
      subtitle: "",
    },
  ];

  const getMaintenancePlant = () => {
    const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/plants/get-plants`;

    axios
      .post(
        API_URL,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${BearerToken}`,
          },
        },
        { withCredentials: true }
      )
      .then((response) =>
        response.data.data.filter((plant) => {
          return plant.id === location.state.plantId;
        })
      )
      .then((resp) => {
        setMaintenancePlant(resp[0]);
      });
  };

  const handleClick = () => {
    console.log("click");
  };

  React.useEffect(() => {
    getMaintenancePlant();
    // back to top button
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  let coordinates = maintenancePlant?.gps;
  // const lng =
  // const lat = coordinates?.split(",")[0].trim().slice(0, -2);
  // console.log(lng, lat);

  return (
    <Box sx={{ margin: "0 32px" }}>
      <h2 style={{ marginBottom: 0 }}>{location.state.title}</h2>
      <p style={{ marginTop: 0, color: "#7C8DB5" }}>
        Manutenzione {location.state.category} - ID {id}
      </p>

      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {/* MAINTENANCE DESCRIPTION */}
        <Grid item xs={6}>
          <Box
            sx={{
              padding: "4%",
              height: "auto",
              borderRadius: "12px",
              border: "1px solid #e6edff",
            }}
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box>
                <h3 style={{ margin: 0 }}>Descrizione attività</h3>
                <p style={{ marginTop: 0, color: "#7C8DB5" }}>
                  Stato: {location.state.status}
                </p>
              </Box>
              <Box sx={{ width: "50%", paddingLeft: "32%" }}>
                <SeverityTag text={location.state.priority} />
              </Box>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <h4 style={{ margin: 0 }}>Data di esecuzione</h4>
                <p style={{ marginTop: 0, color: "#7C8DB5" }}>
                  {location.state.startAt.split("T")[0].replaceAll("-", "/")}
                </p>
              </Box>
              <Box>
                <h3 style={{ margin: 0 }}>Stima tempo esecuzione</h3>
                <p style={{ marginTop: 0, color: "#7C8DB5" }}>-</p>
              </Box>
            </Stack>
            <Box>{location.state.description}</Box>

            <Box sx={{ textAlign: "end" }}>
              <Link
                to={`/checklist/${location.state.checklist.id}`}
                style={{
                  color: "#FE0B00",
                  fontFamily: "Poppins",
                  textDecoration: "none",
                }}
              >
                Vai alla checklist →
              </Link>
            </Box>
          </Box>
        </Grid>
        {/* MAINTENANCE MAP */}
        <Grid item xs={6}>
          <Box
            sx={{
              width: "auto",
              padding: "20px",
              borderRadius: "12px",
              marginBottom: "32px",
              border: "1px solid #e6edff",
            }}
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box>
                <h3 style={{ margin: 0 }}>Posizione impianto</h3>
                <p style={{ marginTop: 0, color: "#7C8DB5" }}>
                  {maintenancePlant?.location}
                </p>
              </Box>
              <Box>
                <CustomButton
                  btnIcon={null}
                  option="outlined-red-small"
                  btnText="Apri in Google Maps"
                  onClick={() =>
                    window.open(
                      "https://maps.google.com?q=" +
                        coordinates.split(",")[0].trim().slice(0, -2) +
                        "," +
                        coordinates.split(",")[1].trim().slice(0, -2)
                    )
                  }
                />
              </Box>
            </Stack>
            {maintenancePlant?.gps && (
              <PlantMap
                lng={coordinates.split(",")[1].trim().slice(0, -2)}
                lat={coordinates.split(",")[0].trim().slice(0, -2)}
              />
            )}
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Box>
                <p style={{ marginBottom: 0 }}>
                  <b>Latitudine</b>
                </p>
                <p style={{ marginTop: 0, color: "#7C8DB5" }}>
                  {maintenancePlant?.gps.split(",")[0].trim()}
                </p>
              </Box>
              <Box>
                <p style={{ marginBottom: 0 }}>
                  <b>Longitudine</b>
                </p>
                <p style={{ marginTop: 0, color: "#7C8DB5" }}>
                  {maintenancePlant?.gps.split(",")[1].trim()}
                </p>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      {backToTopButton && (
        <CustomButton
          option=""
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          btnIcon={<KeyboardArrowUpIcon />}
        />
      )}
    </Box>
  );
}

export default AlertsShow;
