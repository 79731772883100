import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { Stack } from "@mui/material";

import CustomButton from "../../../components/shared/CustomButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

function AddContractorForm({ handleCreate }) {
  const { reset, register, handleSubmit } = useForm();
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogClick = () => {
    setOpenDialog(!openDialog);
    reset({});
  };

  const onSubmit = (data) => {
    setOpenDialog(false);
    // send data to api
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/contractors/create-contractor`;
    const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
    axios
      .post(
        API_URL,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${BearerToken}`,
          },
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.success === 1) {
          handleCreate(data);
          handleDialogClick();
        }
      })
      .catch((error) => console.log(error));
  };

  const DialogFooter = (
    <Stack
      gap={2}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <CustomButton
        btnIcon={null}
        btnText="Annulla"
        option="outlined-red"
        onClick={handleDialogClick}
      />
      <CustomButton
        btnIcon={null}
        btnText="Aggiungi"
        option="filled-red"
        onClick={handleSubmit(onSubmit)}
      />
    </Stack>
  );

  return (
    <div>
      {/* ADD ITEM BUTTON */}
      <CustomButton
        btnText="Aggiungi"
        option="filled-red"
        btnIcon={<AddCircleOutlineOutlinedIcon />}
        onClick={handleDialogClick}
      />
      {/* DIALOG + MODAL */}
      <Dialog
        modal
        className="p-fluid"
        visible={openDialog}
        footer={DialogFooter}
        style={{ width: "32rem" }}
        onHide={handleDialogClick}
        header="Aggiungi società per la manutenzione"
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      >
        {/* FORM */}
        <Stack direction="column" gap={2}>
          {/* COMPANYY NAME */}
          <div className="field">
            <label htmlFor="name">Nome</label>
            <InputText
              id="name"
              required
              autoFocus
              {...register("name", {
                required: "Inserire il nome della società",
              })}
            />
          </div>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {/* COMPANYY EMAIL */}
            <div className="field">
              <label htmlFor="price">Email</label>
              <InputText
                id="email"
                autoFocus
                {...register("email", {
                  pattern: {
                    value: /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/,
                    message: "Inserire una mail valida",
                  },
                })}
              />
            </div>
            {/* COMPANYY PHONE NUMBER */}
            <div className="field">
              <label htmlFor="quantity">Numero di telefono</label>
              <InputText id="quantity" autoFocus {...register("phone")} />
            </div>
          </Stack>
        </Stack>
      </Dialog>
    </div>
  );
}

export default AddContractorForm;
