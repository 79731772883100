import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FE0B00",
    },
    secondary: {
      main: "#6B718",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
});

export default theme;
