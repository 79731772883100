import React from "react";

import PlantsProduction from "./PlantsProduction";

function Graphs() {
  return (
    <div>
      <PlantsProduction />
    </div>
  );
}

export default Graphs;
