import React from "react";
import axios from "axios";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";

import { CircularProgress } from "@mui/material";
import CustomChip from "../../../components/shared/CustomChip";
import { getAPIData } from "../Maintenances/MaintenanceActions";

import "./table.css";
//theme
import "primereact/resources/themes/viva-light/theme.css";

function PlantMaintenances({ plantId, contractor }) {
  const [maintenances, setMaintenances] = React.useState([]);
  const API_URL = `${process.env.REACT_APP_API_BASE_URL}/maintenances/get-maintenances`;

  React.useEffect(() => {
    let mounted = true;
    getAPIData(API_URL).then((items) => {
      if (mounted) {
        setMaintenances(items.data);
      }
    });
    return () => (mounted = false);
  }, []);

  let filteredMaintenances = maintenances;
  filteredMaintenances = filteredMaintenances.filter(
    (maintenance) => maintenance.plant_id === parseInt(plantId)
  );

  // data table
  const [filters, setFilters] = React.useState({
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const statusBodyTemplate = (rowData) => {
    return <CustomChip text={rowData.status} />;
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        value={options.value}
        placeholder="Seleziona"
        optionLabel="status"
        className="w-full md:w-14rem"
        itemTemplate={statusItemTemplate}
        options={["In programma", "In corso", "Sospesa", "Terminata"]}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const statusItemTemplate = (option) => {
    return <CustomChip text={option} />;
  };

  const formatDate = (value) => {
    return value.replaceAll("-", "/").replace("T", " ").replace("Z", "");
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.scheduled_at.slice(0, -5));
  };

  const columns = [
    <Column key="1" field="title" header="Nome attività" sortable></Column>,
    <Column
      key="2"
      field="status"
      header="Stato"
      filter
      body={statusBodyTemplate}
      showFilterMatchModes={false}
      filterElement={statusFilterTemplate}
    ></Column>,
    <Column key="3" field="completed_at" header="Data completamento"></Column>,
    <Column key="4" field="contractor" header="Azienda manutenzione"></Column>,
  ];

  // filteredMaintenances.map(
  //   (maintenance) => (maintenance.companyName = company)
  // );

  return maintenances ? (
    <DataTable
      size="small"
      scrollable
      removableSort
      scrollHeight="400px"
      value={filteredMaintenances}
      style={{ marginLeft: 0, marginRight: 0 }}
      tableStyle={{
        minWidth: "50rem",
        fontSize: "0.875rem",
      }}
    >
      {columns}
    </DataTable>
  ) : (
    <CircularProgress
      color="primary"
      sx={{ marginLeft: "50%", marginTop: "10%" }}
    />
  );
}

export default PlantMaintenances;
