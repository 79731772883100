import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Stack,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import CustomButton from "../../../components/shared/CustomButton";

function EditContractorForm({ record, toggle, setToggle, handleEdit }) {
  const [submittedData, setSubmittedData] = useState({});
  const {
    reset,
    register,
    formState,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useForm({
    record,
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const onSubmit = async (data) => {
    const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/contractors/update-contractor`;

    data.id = record.id;
    // API patch request
    await axios
      .patch(
        API_URL,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${BearerToken}`,
          },
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.success === 1) {
          // callback to index
          handleEdit();
          // reset form and close dialog
          setToggle(false);
          setSubmittedData(data);
        }
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({});
    }
  }, [formState, submittedData, reset]);

  return (
    <div>
      {/* EDIT ITEM DIALOG */}
      <Dialog
        open={toggle}
        onClose={() => {
          setToggle(false);
          reset({});
        }}
      >
        <DialogTitle>Modifica elemento</DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={2} sx={{ marginTop: 1 }}>
            <TextField
              required
              fullWidth
              label="Nome"
              defaultValue={record?.name}
              {...register("name", {
                required: "Inserire il nome della società",
              })}
            />

            <Stack direction="row" gap={2}>
              <TextField
                required
                fullWidth
                label="Email"
                defaultValue={record?.email}
                {...register("email", {
                  pattern: {
                    value: /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/,
                    message: "Inserire una mail valida",
                  },
                })}
              />
              <TextField
                required
                fullWidth
                defaultValue={record?.phone_number}
                label="Numero di telefono"
                {...register("phone_number")}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-around",
          }}
        >
          <Stack direction="row" gap={2}>
            <CustomButton
              btnIconon={null}
              btnText="Annulla"
              option="outlined-red"
              onClick={() => {
                setToggle(false);
                reset({});
              }}
            />
            <CustomButton
              type="submit"
              btnIconon={null}
              btnText="Modifica"
              option="filled-red"
              onClick={handleSubmit(onSubmit)}
            />
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditContractorForm;
