import React from "react";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js/auto";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineGraph({ data }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    return date
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "UTC",
      })
      .replace(",", " -");
  };

  // let xAxisData = data[0].data.slice(0, 50).map((x) => formatDate(x.date));

  // let datasetsArray = [];
  // data.forEach((plant) => {
  //   datasetsArray.push({
  //     label: data[0].name,
  //     data: data[0].data.slice(0, 50).map((x) => x.production),
  //     borderWidth: 2,
  //     borderColor: data[0].colour,
  //     backgroundColor: data[0].colour,
  //   });
  // });

  // const barChartData = {
  //   labels: xAxisData,
  //   datasets: datasetsArray,
  // };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Data e ora",
        },
      },
      y: {
        title: {
          display: true,
          text: "Produzione [kWh]",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          // The title of the tooltip will be the plant name
          title: (tooltipItems) => {
            const datasetIndex = tooltipItems[0].datasetIndex;
            const dataset = tooltipItems[0].chart.data.datasets[datasetIndex];
            return dataset.label; // Plant name from the dataset label
          },
          // The body of the tooltip will be the production value
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            return `Production: ${value}`;
          },
        },
      },
    },
  };

  return data && <Line data={data} options={options} />;
}

export default LineGraph;
