export const PlantNameIdObject = {
  45: "(25) E40 Ven.F51P65",
  46: "(35) E40 Ven.F51P67",
  47: "(36) E40 Ven.F51P86",
  48: "(37) E40 Ven.F51P89",
  49: "(38) E40 Ven.F51P133",
  50: "(39) E40 Bar.F19P551A",
  51: "(40) E40 Bar.F19P551C",
  52: "(32) E40 Tric.F78P50A/1",
  53: "(33) E40 Tric.F78P50B/2",
  54: "(31) E40 Ven.F61P96",
  55: "(41) E40 Ven.F73P5B",
  56: "(42) E40 Ven.F73P217",
  57: "(43) E40 Ven.F73P5A",
  58: "(30) E40 Ven.F73P230A",
  59: "(itav12), Ven. V80, Piano Regio",
  60: "WT1 V90 Foiano",
  61: "WT3 V90 Foiano",
  62: "WT4 V90 Foiano",
  63: "WT5 V90 Foiano",
  64: "WT6 V90 Foiano",
};
