import React from "react";
import { useNavigate } from "react-router-dom";

import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
//theme
import "primereact/resources/themes/viva-light/theme.css";

import { IconButton, Tooltip } from "@mui/material";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";

import "./table.css";
import CustomChip from "../../../components/shared/CustomChip";
import SeverityTag from "../../../components/shared/SeverityTag";

function PlantsDataTable({ data, handleEdit }) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = React.useState(null);

  const [filters, setFilters] = React.useState({
    name: { value: null, matchMode: FilterMatchMode.EQUALS },
    location: { value: null, matchMode: FilterMatchMode.EQUALS },
    rotorRunning: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  // ###################### FILTERS #################################
  const locationBodyTemplate = (rowData) => {
    return <CustomChip text={rowData.location} />;
  };

  const locationFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        value={options.value}
        placeholder="Seleziona"
        optionLabel="location"
        className="w-full md:w-14rem"
        itemTemplate={locationItemTemplate}
        options={[
          "Park Venosa ",
          "Park Barile ",
          "Park Foiano ",
          "Park Tricarico ",
        ]}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const locationItemTemplate = (option) => {
    return <CustomChip text={option} />;
  };

  const statusBodyTemplate = (rowData) => {
    return <SeverityTag text={rowData.rotor_running} />;
  };

  const statusItemTemplate = (option) => {
    return <SeverityTag text={option} />;
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        value={options.value}
        placeholder="Seleziona"
        optionLabel="rotorRunning"
        className="w-full md:w-14rem"
        itemTemplate={statusItemTemplate}
        options={["Run", "Stop", "Emergency"]}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  // ###############################################################

  const columns = [
    <Column key="1" field="name" header="Nome" sortable></Column>,
    <Column
      key="2"
      field="location"
      header="Località"
      filter
      body={locationBodyTemplate}
      showFilterMatchModes={false}
      filterElement={locationFilterTemplate}
    ></Column>,
    <Column
      key="3"
      field="production"
      header="Produzione [kW]"
      sortable
    ></Column>,
    <Column key="4" field="wind_speed" header="Velocità vento [m/s]"></Column>,
    <Column key="5" field="current_status" header="Stato impianto"></Column>,
    <Column
      key="6"
      header="Stato"
      field="rotorRunning"
      filter
      body={statusBodyTemplate}
      showFilterMatchModes={false}
      filterElement={statusFilterTemplate}
    ></Column>,
  ];

  const rowClass = (rowData) => (rowData.isFaulty ? "bg-red-100" : "");

  const onRowSelect = (event) => {
    navigate(`/plants/${event.data.id}`, { state: event.data });
  };

  // ##################### CSV EXPORT ###############################

  const exportExcel = () => {
    const slimData = data.map(
      ({
        id,
        group,
        description,
        is_active,
        is_faulty,
        slug,
        created_at,
        updated_at,
        ...rest
      }) => ({
        ...rest,
      })
    );

    const filename = "Impianti_" + new Date().getTime() + ".xlsx";

    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(slimData);

      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Impianti");

      /* fix headers */
      xlsx.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "Nome impianto",
            "Velocità vento [m/s]",
            "Power supply",
            "Energia prodotta oggi [kWh]",
            "Parco",
            "Coordinate GPS",
            "Rotor Running",
            "Velocità Rotori [rpm]",
            "Produzione [kW]",
            "Warning attuale",
            "Stato attuale",
            "Stato turbine",
            "Numero fault turbine oggi",
            "Transmission DCE_WT [s]",
            "Transmission DCE_TC [s]",
            "Numero fault power supply oggi",
            "Modalità monitoraggio",
            "Energia prodotta ieri [kWh]",
            "Energai prodotta nel mese scorso [kWh]",
            "Energia prodotta questo mese [kWh]",
            "Energia prodotta anno scorso [kWh]",
            "Energia prodotta questo anno [kWh]",
            "Vento minimo ieri [m/s]",
            "Vento minimo oggi [m/s]",
            "Vento medio ieri [m/s]",
            "Vento medio oggi [m/s]",
            "Vento massimo ieri [m/s]",
            "Vento massimo oggi [m/s]",
          ],
        ],
        {
          origin: "A1",
        }
      );

      xlsx.writeFile(workbook, filename);
    });
  };

  const header = (
    <div style={{ paddingLeft: "95%" }}>
      <Tooltip title="Esporta in Excel">
        <IconButton onClick={exportExcel}>
          <ContentPasteGoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  //################################################################

  return (
    <div>
      <DataTable
        dataKey="id"
        selectionMode="single"
        selection={selectedItem}
        onRowSelect={onRowSelect}
        onSelectionChange={(e) => setSelectedItem(e.value)}
        //////////////////
        value={data}
        size="small"
        scrollable
        scrollHeight="600px"
        removableSort
        header={header}
        rowClassName={rowClass}
        tableStyle={{
          minWidth: "50rem",
          fontSize: "0.875rem",
        }}
        filters={filters}
        globalFilterFields={["name", "location", "rotor_running"]}
      >
        {columns}
      </DataTable>
    </div>
  );
}

export default PlantsDataTable;
