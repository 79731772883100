import axios from "axios";
import React from "react";
import { useLocation } from "react-router-dom";

import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
//theme
import "primereact/resources/themes/viva-light/theme.css";

import { Stack, IconButton, Tooltip } from "@mui/material";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";

import "./table.css";
import SeverityTag from "../../../components/shared/SeverityTag";
import CustomButton from "../../../components/shared/CustomButton";
import AddMaintenanceForm from "../Maintenances/AddMaintenanceForm";

function AlertsDataTable({ data, handleDelete, handleCreateMaintenance }) {
  const location = useLocation();
  const pathname = location.pathname;

  // ###################### FILTERS #################################

  const [filters, setFilters] = React.useState({
    priority: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const priorityBodyTemplate = (rowData) => {
    return <SeverityTag text={rowData.priority} />;
  };

  const priorityFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        value={options.value}
        placeholder="Seleziona"
        optionLabel="priority"
        className="w-full md:w-14rem"
        itemTemplate={priorityItemTemplate}
        options={["Alta", "Media", "Bassa"]}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const priorityItemTemplate = (option) => {
    return <SeverityTag text={option} />;
  };

  const formatDate = (value) => {
    if (value.includes("T")) {
      return value.replaceAll("-", "/").replace("T", "-").replace("Z", "");
    } else {
      return value;
    }
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.date_string.slice(0, -5));
  };

  // ###############################################################

  const [createMaintenanceDialog, setCreateMaintenanceDialog] =
    React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [unarchiveDialog, setUnarchiveDialog] = React.useState(false);

  // Toggle dialogs
  const handleDeleteDialogClick = () => {
    setDeleteDialog(!deleteDialog);
  };

  const handleCreateMaintenanceDialogClick = () => {
    setCreateMaintenanceDialog(!createMaintenanceDialog);
  };

  const handleUnarchiveAlertDialogClick = () => {
    setUnarchiveDialog(!unarchiveDialog);
  };

  const onUnarchive = () => {
    const form = new FormData();
    form.append("id", selectedRecord.id);
    const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
    const unarchiveUrl = `${process.env.REACT_APP_API_BASE_URL}/alerts/unarchive-alert`;

    axios
      .post(unarchiveUrl, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${BearerToken}`,
        },
      })
      .then((response) => {
        if (response.data.success === 1) {
          // callback to maintenance index
          handleUnarchiveAlertDialogClick();
          handleDelete(selectedRecord.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Action buttons (i.e., Create maintenance, Delete)
  const actionBodyTemplate = (rowData) => {
    if (pathname === "/archived-alerts") {
      return (
        <Stack direction="row" gap={2}>
          <IconButton
            size="small"
            onClick={() => {
              handleUnarchiveAlertDialogClick();
              setSelectedRecord(rowData);
            }}
          >
            <Tooltip title="Ripristina alert">
              <UnarchiveOutlinedIcon />
            </Tooltip>
          </IconButton>
        </Stack>
      );
    }

    return (
      <Stack direction="row" gap={2}>
        <Tooltip title="Crea un'attività di manutenzione per questo alert">
          <IconButton
            size="small"
            onClick={() => {
              handleCreateMaintenanceDialogClick();
              setSelectedRecord(rowData);
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          size="small"
          onClick={() => {
            handleDeleteDialogClick();
            setSelectedRecord(rowData);
          }}
        >
          <Tooltip title="Archivia alert. Gli alert archiviati verrano eliminati dopo 30 giorni dall'archiviazione">
            <ArchiveOutlinedIcon />
          </Tooltip>
        </IconButton>
      </Stack>
    );
  };

  // Add errorcode from plant
  // const [plants, setPlants] = React.useState([]);
  // const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
  // async function getAPIData() {
  //   return await axios
  //     .post(
  //       `${process.env.REACT_APP_API_BASE_URL}/plants/get-plants`,
  //       {},
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${BearerToken}`,
  //         },
  //       }
  //     )
  //     .then((response) => response.data.data);
  // }
  // React.useEffect(() => {
  //   let mounted = true;
  //   getAPIData().then((items) => {
  //     if (mounted) {
  //       setPlants(items);
  //     }
  //   });
  //   return () => (mounted = false);
  // }, []);
  // const arr2Map = new Map(
  //   plants.map((plant) => [
  //     plant.id,
  //     { errorCode: plant.errorCode, name: plant.name },
  //   ])
  // );
  // data.forEach((maintenance) => {
  //   const plantInfo = arr2Map.get(maintenance.plantId) || {
  //     errorCode: "-",
  //     name: "-",
  //   };
  //   maintenance.errorCode = plantInfo.errorCode;
  //   maintenance.plantName = plantInfo.name;
  // });

  // Data table columns

  const columns = [
    <Column
      key="1"
      field="message"
      header="Messaggio di allarme"
      sortable
    ></Column>,
    <Column key="2" field="pdb_label" header="Impianto" sortable></Column>,
    <Column
      key="3"
      field="priority"
      header="Priorità"
      filter
      body={priorityBodyTemplate}
      showFilterMatchModes={false}
      filterElement={priorityFilterTemplate}
    ></Column>,
    <Column
      key="4"
      field="date_string"
      header="Data"
      sortable
      body={dateBodyTemplate}
    ></Column>,
    <Column key="5" field="state" header="Stato"></Column>,
    <Column key="6" body={actionBodyTemplate} exportable={false}></Column>,
  ];

  // Submit delete request
  const onSubmit = () => {
    const form = new FormData();
    form.append("id", selectedRecord.id);
    const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
    const archiveUrl = `${process.env.REACT_APP_API_BASE_URL}/alerts/archive-alert`;

    axios
      .post(archiveUrl, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${BearerToken}`,
        },
      })
      .then((response) => {
        if (response.data.success === 1) {
          // callback to maintenance index
          handleDeleteDialogClick();
          handleDelete(selectedRecord.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Delete dialog footer
  const DialogFooter = (
    <Stack
      gap={2}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <CustomButton
        btnIcon={null}
        btnText="Annulla"
        option="outlined-red"
        onClick={handleDeleteDialogClick}
      />
      <CustomButton
        btnIcon={null}
        btnText="Archivia"
        option="filled-red"
        onClick={onSubmit}
      />
    </Stack>
  );

  // Unarchive dialog footer
  const unarchiveDialogFooter = (
    <Stack
      gap={2}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <CustomButton
        btnIcon={null}
        btnText="Annulla"
        option="outlined-red"
        onClick={handleUnarchiveAlertDialogClick}
      />
      <CustomButton
        btnIcon={null}
        btnText="Ripristina"
        option="filled-red"
        onClick={onUnarchive}
      />
    </Stack>
  );

  // ##################### CSV EXPORT ###############################
  const exportExcel = () => {
    const dataNoChecklist = data.map(
      ({
        id,
        appId,
        score,
        quality,
        plantId,
        checklist,
        completeAt,
        description,
        ...rest
      }) => ({
        ...rest,
      })
    );

    const filename = "Alerts_" + new Date().getTime() + ".xlsx";

    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataNoChecklist);

      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Manutenzioni");

      /* fix headers */
      xlsx.utils.sheet_add_aoa(
        worksheet,
        [["Priorità", "Stato", "Messaggio d'errore", "Impianto", "Data"]],
        {
          origin: "A1",
        }
      );

      xlsx.writeFile(workbook, filename);
    });
  };

  const header = (
    <div style={{ paddingLeft: "95%" }}>
      <Tooltip title="Esporta in Excel">
        <IconButton onClick={exportExcel}>
          <ContentPasteGoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  //################################################################

  return (
    <div>
      <DataTable
        dataKey="id"
        scrollable
        scrollHeight="600px"
        ///////////////////
        value={data}
        size="small"
        removableSort
        header={header}
        tableStyle={{
          minWidth: "50rem",
          fontSize: "0.875rem",
        }}
        filters={filters}
      >
        {columns}
      </DataTable>
      {/* DELETE ITEM DIALOG */}
      <Dialog
        modal
        footer={DialogFooter}
        visible={deleteDialog}
        style={{ width: "32rem" }}
        onHide={handleDeleteDialogClick}
        header="Conferma archiviazione"
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              ⚠️ Sei sicuro di voler archiviare questo elemento? Gli elementi
              archiviati saranno eliminati automaticamente dopo 30 giorni.
            </span>
          }
        </div>
      </Dialog>
      {/* UNARCHIVE ITEM DIALOG */}
      <Dialog
        modal
        footer={unarchiveDialogFooter}
        visible={unarchiveDialog}
        style={{ width: "32rem" }}
        onHide={handleUnarchiveAlertDialogClick}
        header="Conferma ripristino"
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {
            <span>
              ⚠️ Sei sicuro di voler ripristinare questo elemento? Questo
              elemento non sarà più eliminato automaticamente dopo 30 giorni.
            </span>
          }
        </div>
      </Dialog>
      {/* ADD MAINTENANCE ITEM DIALOG */}
      <AddMaintenanceForm
        toggle={createMaintenanceDialog}
        record={selectedRecord}
        handleCreate={handleCreateMaintenance}
        setToggle={setCreateMaintenanceDialog}
      />
    </div>
  );
}

export default AlertsDataTable;
