import axios from "axios";

// get data from API
export async function getAPIData(API_URL) {
  return await axios
  .get( API_URL, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${
          JSON.parse(localStorage.currentUser).accessToken
        }`,
      },
    })
  .then((response) => response.data);
}
