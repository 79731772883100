import axios from "axios";
import React from "react";

import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Stack, IconButton } from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import "./table.css";
import EditCompanyForm from "./EditContractorForm";
import CustomButton from "../../../components/shared/CustomButton";
//theme
import "primereact/resources/themes/viva-light/theme.css";

function ContractorsDataTable({ data, handleDelete, handleEdit }) {
  const [editDialog, setEditDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);

  const handleDeleteDialogClick = () => {
    setDeleteDialog(!deleteDialog);
  };

  const handleEditDialogClick = () => {
    setEditDialog(!editDialog);
  };

  // action buttons
  const actionBodyTemplate = (rowData) => {
    return (
      <Stack direction="row" gap={2}>
        <IconButton
          size="small"
          onClick={() => {
            handleEditDialogClick();
            setSelectedRecord(rowData);
          }}
        >
          <BorderColorOutlinedIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            handleDeleteDialogClick();
            setSelectedRecord(rowData);
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Stack>
    );
  };

  // columns
  const columns = [
    <Column key="1" field="name" header="Nome" sortable></Column>,
    <Column key="2" field="email" header="Email"></Column>,
    <Column key="3" field="phone_number" header="Numero di telefono"></Column>,
    <Column key="4" field="contractor_idx" header="Codice società"></Column>,
    <Column key="5" body={actionBodyTemplate} exportable={false}></Column>,
  ];

  // Submit delete request
  const onSubmit = (data) => {
    const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/contractors/delete-contractor`;

    axios
      .delete(
        API_URL,
        {
          data: { id: selectedRecord.id },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${BearerToken}`,
          },
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.success === 1) {
          // callback to maintenance index
          handleDeleteDialogClick();
          handleDelete(selectedRecord.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // dialog footer
  const DialogFooter = (
    <Stack
      gap={2}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <CustomButton
        btnIcon={null}
        btnText="Annulla"
        option="outlined-red"
        onClick={handleEditDialogClick}
      />
      <CustomButton
        btnIcon={null}
        btnText="Elimina"
        option="filled-red"
        onClick={onSubmit}
      />
    </Stack>
  );

  return (
    <div>
      <DataTable
        value={data}
        scrollable
        scrollHeight="600px"
        size="small"
        removableSort
        tableStyle={{
          minWidth: "50rem",
          fontSize: "0.875rem",
        }}
      >
        {columns}
      </DataTable>
      {/* DELETE ITEM DIALOG */}
      <Dialog
        modal
        footer={DialogFooter}
        visible={deleteDialog}
        style={{ width: "32rem" }}
        onHide={handleDeleteDialogClick}
        header="Conferma eliminazione"
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>⚠️ Sei sicuro di voler eliminare questo elemento?</span>}
        </div>
      </Dialog>
      {/* EDIT ITEM DIALOG */}
      <EditCompanyForm
        toggle={editDialog}
        record={selectedRecord}
        handleEdit={handleEdit}
        setToggle={setEditDialog}
      />
    </div>
  );
}

export default ContractorsDataTable;
