import React, { Component } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoginPage from "./scenes/LoginPage";
import PrivateRoutes from "./utils/PrivateRoutes";
import "./components/shared/Notifications/messaging_init_in_sw";
// INDEX PAGES
import AlertsIndex from "./scenes/dashboard/Alerts/AlertsIndex";
import ArchivedAlertsIndex from "./scenes/dashboard/Alerts/ArchivedAlertsIndex";
import ChecklistsIndex from "./scenes/dashboard/Checklists/ChecklistsIndex";
import ChecklistShow from "./scenes/dashboard/Checklists/ChecklistShow";
import ContractorsIndex from "./scenes/dashboard/Contractors/ContractorsIndex";
import Graphs from "./scenes/dashboard/Graphs/Graphs";
import MaintenancesIndex from "./scenes/dashboard/Maintenances/MaintenancesIndex";
import PeriodicMaintenancesIndex from "./scenes/dashboard/Maintenances/PeriodicMaintenancesIndex";
import MaintenanceShow from "./scenes/dashboard/Maintenances/MaintenanceShow";
import PlantsIndex from "./scenes/dashboard/Plants/PlantsIndex";
import PlantShow from "./scenes/dashboard/Plants/PlantShow";
import IndexMainContent from "./scenes/global/IndexMainContent";

export const Context = React.createContext();

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      currentUser: {},
      loggedInStatus: "NOT_LOGGED_IN",
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogin(data) {
    this.setState({
      currentUser: data,
      loggedInStatus: "LOGGED_IN",
    });
  }

  checkLoginStatus() {
    if (
      localStorage.getItem("loggedInStatus") === "LOGGED_IN" &&
      this.state.loggedInStatus === "NOT_LOGGED_IN"
    ) {
      this.setState({
        loggedInStatus: "LOGGED_IN",
        currentUser: JSON.parse(localStorage.currentUser),
      });
    } else if (
      (localStorage.getItem("loggedInStatus") === "NOT_LOGGED_IN" ||
        localStorage.getItem("loggedInStatus") === "undefined") &&
      this.state.loggedInStatus === "LOGGED_IN"
    ) {
      this.setState({
        loggedInStatus: "NOT_LOGGED_IN",
        currentUser: {},
      });
    }
  }
  componentDidMount() {
    this.checkLoginStatus();
  }

  handleLogout() {
    this.setState({
      currentUser: {},
      loggedInStatus: "NOT_LOGGED_IN",
    });
    localStorage.setItem("loggedInStatus", "NOT_LOGGED_IN");
  }

  render() {
    return (
      <Context.Provider value={this.state.loggedInStatus}>
        <BrowserRouter>
          <Routes>
            {/* ROOT - LOGIN PAGE */}
            <Route
              exact
              path="/"
              element={
                <LoginPage
                  handleLogin={this.handleLogin}
                  loggedInStatus={this.state.loggedInStatus}
                />
              }
            />
            {/* PRIVATE ROUTES */}
            <Route
              element={
                <PrivateRoutes
                  loggedInStatus={localStorage.getItem("loggedInStatus")}
                />
              }
            >
              {/* DATAMATIC ERRORS INDEX */}
              <Route
                exact
                path="/alerts"
                element={
                  <IndexMainContent
                    title="Notifiche Datamatic"
                    handleLogout={this.handleLogout}
                    pageComponent={<AlertsIndex />}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarUrl}
                    subtitle="Gestisci gli errori degli impianti"
                  />
                }
              />

              <Route
                exact
                path="/archived-alerts"
                element={
                  <IndexMainContent
                    title="Notifiche Datamatic"
                    handleLogout={this.handleLogout}
                    pageComponent={<ArchivedAlertsIndex />}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarUrl}
                    subtitle="Gestisci gli errori degli impianti"
                  />
                }
              />
              {/* CHECKLIST */}
              <Route
                exact
                path="/checklists"
                element={
                  <IndexMainContent
                    title="Checklist inviate dai tecnici"
                    handleLogout={this.handleLogout}
                    pageComponent={<ChecklistsIndex />}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarUrl}
                    subtitle="Gestisci le checklist inviate dai tecnici"
                  />
                }
              />
              <Route
                path="/checklists/:id"
                element={
                  <IndexMainContent
                    handleLogout={this.handleLogout}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarURL}
                    title=""
                    subtitle=""
                    pageComponent={<ChecklistShow />}
                  />
                }
              />
              {/* DATAMATIC ERRORS INDEX */}
              <Route
                exact
                path="/maintenances"
                element={
                  <IndexMainContent
                    handleLogout={this.handleLogout}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarURL}
                    title="Pagina manutenzioni"
                    subtitle="Gestisci attività di manutenzione"
                    pageComponent={<MaintenancesIndex />}
                  />
                }
              />
              <Route
                exact
                path="/periodic-maintenances"
                element={
                  <IndexMainContent
                    handleLogout={this.handleLogout}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarURL}
                    title="Pagina manutenzioni"
                    subtitle="Gestisci attività di manutenzione"
                    pageComponent={<PeriodicMaintenancesIndex />}
                  />
                }
              />
              <Route
                path="/maintenances/:id"
                element={
                  <IndexMainContent
                    handleLogout={this.handleLogout}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarURL}
                    title=""
                    subtitle=""
                    pageComponent={<MaintenanceShow />}
                  />
                }
              />
              {/* POWER PLANTS INDEX */}
              <Route
                exact
                path="/plants"
                element={
                  <IndexMainContent
                    handleLogout={this.handleLogout}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarURL}
                    title="Pagina impianti"
                    subtitle="Gestisci i tuoi impianti"
                    pageComponent={<PlantsIndex />}
                  />
                }
              />
              <Route
                path="/plants/:id"
                element={
                  <IndexMainContent
                    handleLogout={this.handleLogout}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarURL}
                    title=""
                    subtitle=""
                    pageComponent={<PlantShow />}
                  />
                }
              />
              {/* COMPANIES INDEX */}
              <Route
                exact
                path="/contractors"
                element={
                  <IndexMainContent
                    handleLogout={this.handleLogout}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarURL}
                    title="Pagina società"
                    subtitle="Gestisci le società di manutenzione"
                    pageComponent={<ContractorsIndex />}
                  />
                }
              />
              {/* GRAPHS INDEX */}
              <Route
                exact
                path="/graphs"
                element={
                  <IndexMainContent
                    handleLogout={this.handleLogout}
                    username={this.state.currentUser.name}
                    // avatarURL={this.state.currentUser.avatarURL}
                    title="Pagina grafici"
                    subtitle="Analizza i tuoi dati"
                    pageComponent={<Graphs />}
                  />
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Context.Provider>
    );
  }
}
