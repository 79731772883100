import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Menu,
  Stack,
  Button,
  Avatar,
  AppBar,
  Tooltip,
  Toolbar,
  MenuItem,
  Container,
  Typography,
  ListItemIcon,
} from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const settings = ["Segnala un bug", "Logout"];

function Appbar({ title, subTitle, username, handleLogout, avatarURL }) {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleLogoutClick = () => {
    handleLogout();
    navigate("/");
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      color="secondary"
      sx={{ marginTop: "2.5%" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          {/* APPBAR LEFT SIDE */}
          <Stack>
            <Typography
              noWrap
              variant="h3"
              component="div"
              sx={{
                mr: 2,
                fontWeight: 500,
                fontSize: "28px",
                fontFamily: "Poppins",
              }}
            >
              {title}
            </Typography>
            <Typography
              noWrap
              variant="p"
              component="div"
              sx={{
                mr: 2,
                fontWeight: 400,
                fontSize: "16px",
                color: "#7C8DB5",
                fontFamily: "Poppins",
              }}
            >
              {subTitle}
            </Typography>
          </Stack>
          {/* APPBAR RIGHT SIDE */}
          <Stack direction="row" alignItems="center" gap={2}>
            <Tooltip title="Apri menu utente">
              <Button
                sx={{ p: 0, color: "#232020", textTransform: "none" }}
                onClick={(event) => setAnchorElUser(event.currentTarget)}
                startIcon={
                  <Avatar alt="Account owner avatar picture" src={avatarURL} />
                }
                endIcon={<KeyboardArrowDownOutlinedIcon />}
              >
                {username}
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={() => setAnchorElUser(null)}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() =>
                    setting === "Logout"
                      ? handleLogoutClick()
                      : window.open(
                          "https://docs.google.com/spreadsheets/d/1TKEHLX-McBYl4wjtLgP4BORkGPH-TKJps5ZWU7qtILY/edit?usp=sharing"
                        )
                  }
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    color: setting === "Logout" ? "#FE0B00" : "#6B718",
                  }}
                >
                  <ListItemIcon>
                    {setting === "Logout" ? (
                      <LogoutOutlinedIcon fontSize="small" color="primary" />
                    ) : (
                      <BugReportOutlinedIcon
                        fontSize="small"
                        color="secondary"
                      />
                    )}
                  </ListItemIcon>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Appbar;
