import axios from "axios";
import React, { useState } from "react";

import {
  Box,
  Container,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import logo from "../../assets/logo.png";
import CustomButton from "../shared/CustomButton";

function Login({ handleSuccessfulAuth }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [currentUser, setCurrentUser] = useState({
    email: "",
    password: "",
    accessToken: "",
  });
  const API_URL = `${process.env.REACT_APP_API_BASE_URL}/auth/admin-login`;

  const handleChange = (event) => {
    setCurrentUser({ ...currentUser, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    axios
      .post(API_URL, currentUser, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        if (resp.data.success === 1) {
          localStorage.setItem("loggedInStatus", "LOGGED_IN");

          resp.data.data.accessToken = resp.data.accessToken;
          localStorage.setItem("currentUser", JSON.stringify(resp.data.data));

          handleSuccessfulAuth(resp.data.data, resp.data.accessToken);
        }
      })
      .catch((error) => {
        console.log("Login error:", console.error);
      });
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          src={logo}
          component="img"
          alt="Company logo"
          sx={{
            height: 90,
            width: 200,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
        />
        <h2>Accedi</h2>

        <Box noValidate sx={{ mt: 1 }} component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            required
            id="email"
            autoFocus
            name="email"
            label="Email"
            autoComplete="email"
            onChange={handleChange}
            sx={{ marginBottom: 3 }}
          />

          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              onChange={handleChange}
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Ricordami"
            /> */}
          <CustomButton
            onClick={null}
            btnIcon={null}
            btnText="Accedi"
            option="filled-red"
          />
          {/* <Grid container>
            <Grid item xs textAlign="end">
              <Link
                href="#"
                underline="hover"
                sx={{ fontSize: 14, color: "#FE0B00", fontWeight: 400 }}
              >
                Hai dimenticato la password?
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
