import React from "react";

import { Box } from "@mui/material";

function PlantGraph() {
  return (
    <Box
      sx={{
        padding: "4%",
        height: "auto",
        marginTop: '16px',
        borderRadius: "12px",
        marginBottom: "32px",
        border: "1px solid #e6edff",
      }}
    >
      <h3>Disponibilità impianto</h3>
    </Box>
  );
}

export default PlantGraph;
