import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Stack,
  Alert,
  Snackbar,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { getAPIData } from "./AlertsActions";
import AlertsDataTable from "./AlertsDataTable";
import CustomButton from "../../../components/shared/CustomButton";

export const Context = React.createContext();

function AlertsIndex() {
  const query = useRef();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [backToTopButton, setBackToTopButton] = useState(false);

  const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
  const API_URL = `${process.env.REACT_APP_API_BASE_URL}/alerts/get-alerts`;

  useEffect(() => {
    let mounted = true;
    function callAPI(API_URL) {
      getAPIData(API_URL)
        .then((items) => {
          if (mounted) {
            setRecords(items.data);
          }
        })
        .finally(() => {
          setLoading(false);
          // back to top button
          window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
              setBackToTopButton(true);
            } else {
              setBackToTopButton(false);
            }
          });
        });
    }

    callAPI(API_URL);
  }, []);

  // query the db for a search
  const filteredRecords = async (query) => {
    const queryUrl = `${API_URL}?text=${query}`;

    await axios
      .get(queryUrl, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${BearerToken}`,
        },
      })
      .then((response) => {
        setRecords(response.data.data);
      });
  };

  // update component after deleting record
  const handleDelete = (recordID) => {
    let filteredData = [...records];
    // find record with the same index as the item to be deleted
    const foundRecordIndex = filteredData.findIndex(
      (entry) => entry.id === recordID
    );
    // If we find the record with matching ID, remove it
    if (foundRecordIndex !== -1) filteredData.splice(foundRecordIndex, 1);
    setRecords(filteredData);

    // display success alert
    setAlertContent("Elemento archiviato.");
    setAlert(true);
  };

  const handleCreateMaintenance = () => {
    // display success alert
    setAlertContent("Nuova attività di manutenzione inserita nel database.");
    setAlert(true);
  };

  return (
    <Box>
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={() => setAlert(false)}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={() => setAlert(false)}
        >
          {alertContent}
        </Alert>
      </Snackbar>
      <Stack
        useFlexGap
        spacing={40}
        margin="0 22px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* SEARCHBAR */}
        <TextField
          type="search"
          inputRef={query}
          placeholder="Cerca"
          InputProps={{
            style: {
              width: "150%",
              height: "57px",
              maring: "11px 0",
              borderRadius: "12px",
              border: "1px solid #E6EDFF",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          onChange={() => filteredRecords(query.current.value)}
        />
        {/* <div>
          <CustomButton
            option="outlined-gray"
            btnText="Filtra riusltati"
            onClick={() => console.log("open filter window")}
            btnIcon={<FilterAltOutlinedIcon />}
          />
        </div> */}
      </Stack>
      {/* DATA TABLE */}
      <div>
        {loading ? (
          <CircularProgress
            color="primary"
            sx={{ marginLeft: "50%", marginTop: "10%" }}
          />
        ) : (
          <AlertsDataTable
            data={records}
            option="current"
            handleDelete={handleDelete}
          />
        )}
      </div>
      {backToTopButton && (
        <CustomButton
          option=""
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          btnIcon={<KeyboardArrowUpIcon />}
        />
      )}
    </Box>
  );
}

export default AlertsIndex;
