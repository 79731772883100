import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import {
  Stack,
  Radio,
  Dialog,
  MenuItem,
  TextField,
  FormLabel,
  RadioGroup,
  DialogTitle,
  FormControl,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import CustomButton from "../../../components/shared/CustomButton";
import { getAPIData } from "./MaintenanceActions";

function EditMaintenanceForm({ record, toggle, setToggle, handleEdit }) {
  const PLANTS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/plants/get-plants`;
  const [submittedData, setSubmittedData] = useState({});
  const [plants, setPlants] = useState(null);
  const {
    reset,
    register,
    formState,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useForm({
    record,
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  React.useEffect(() => {
    let mounted = true;
    // get data from API
    getAPIData(PLANTS_API_URL).then((items) => {
      if (mounted) {
        const plantsArray = items.data.map((item) => ({
          id: item.id,
          name: item.name,
          current_warning: item.current_warning,
        }));

        setPlants(plantsArray);
      }
    });

    return () => (mounted = false);
  }, []);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const tz = "Europe/Rome";

  const onSubmit = async (data) => {
    const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/maintenances/update-maintenance`;

    data.id = record.id;
    data.scheduled_at = dayjs(data.scheduled_at).utc().format();

    console.log(data);

    // API patch request
    await axios
      .patch(
        API_URL,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${BearerToken}`,
          },
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.success === 1) {
          // callback to index
          handleEdit();
          // reset form and close dialog
          setToggle(false);
          setSubmittedData(data);
        }
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({});
    }
  }, [formState, submittedData, reset]);

  return (
    <div>
      {/* EDIT ITEM DIALOG */}
      <Dialog
        open={toggle}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setToggle(false);
          reset({});
        }}
      >
        <DialogTitle>Modifica elemento</DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={2} sx={{ marginTop: 1 }}>
            <TextField
              required
              fullWidth
              label="Titolo attività"
              defaultValue={record?.title}
              {...register("title", {
                required: "Inserire il titolo della attività",
              })}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Data e ora attività di manutenzione"
                defaultValue={dayjs.tz(record?.scheduled_at, tz)}
                {...register("scheduled_at")}
              />
            </LocalizationProvider>

            <TextField
              fullWidth
              multiline
              rows={4}
              label="Descrizione"
              defaultValue={record?.description}
              {...register("description")}
            />

            <Stack direction="row" justifyContent="space-around">
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Categoria
                </FormLabel>
                <RadioGroup
                  name="radio-buttons-group"
                  defaultValue={record?.category}
                >
                  <FormControlLabel
                    value="Correttiva"
                    control={<Radio />}
                    label="Correttiva"
                    {...register("category")}
                  />
                  <FormControlLabel
                    value="Integrativa"
                    control={<Radio />}
                    label="Integrativa"
                    {...register("category")}
                  />
                  <FormControlLabel
                    value="Preventiva"
                    control={<Radio />}
                    label="Preventiva"
                    {...register("category")}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Priorità
                </FormLabel>
                <RadioGroup
                  defaultValue={record?.priority}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Alta"
                    label="Alta"
                    control={<Radio />}
                    {...register("priority")}
                  />
                  <FormControlLabel
                    value="Media"
                    label="Media"
                    control={<Radio />}
                    {...register("priority")}
                  />
                  <FormControlLabel
                    value="Bassa"
                    label="Bassa"
                    control={<Radio />}
                    {...register("priority")}
                  />
                </RadioGroup>
              </FormControl>
            </Stack>

            {plants && (
              <TextField
                select
                required
                label="Seleziona un impianto"
                defaultValue={record?.plant_id}
                {...register("plant_id")}
              >
                {plants.map((plant, idx) => (
                  <MenuItem key={idx} value={parseInt(plant.id)}>
                    {plant.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-around",
          }}
        >
          <Stack direction="row" gap={2}>
            <CustomButton
              btnIconon={null}
              btnText="Annulla"
              option="outlined-red"
              onClick={() => {
                setToggle(false);
                reset({});
              }}
            />
            <CustomButton
              type="submit"
              btnIconon={null}
              btnText="Modifica"
              option="filled-red"
              onClick={handleSubmit(onSubmit)}
            />
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditMaintenanceForm;
