import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
//theme
import "primereact/resources/themes/viva-light/theme.css";

import { Stack, IconButton, Tooltip } from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";

import "./table.css";
import EditMaintenanceForm from "./EditMaintenanceForm";
import CustomChip from "../../../components/shared/CustomChip";
import SeverityTag from "../../../components/shared/SeverityTag";
import CustomButton from "../../../components/shared/CustomButton";

function PeriodicMaintenancesDataTable({ data, handleDelete, handleEdit }) {
  const [selectedItem, setSelectedItem] = React.useState(null);

  // ###################### FILTERS #################################

  const [filters, setFilters] = React.useState({
    priority: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    category: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const priorityBodyTemplate = (rowData) => {
    return <SeverityTag text={"Alta"} />;
  };

  const priorityFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        value={options.value}
        placeholder="Seleziona"
        optionLabel="priority"
        className="w-full md:w-14rem"
        itemTemplate={priorityItemTemplate}
        options={["Alta", "Media", "Bassa"]}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const priorityItemTemplate = (option) => {
    return <SeverityTag text={option} />;
  };

  const categoryBodyTemplate = (rowData) => {
    return <CustomChip text={"Preventiva"} />;
  };

  const categoryFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        value={options.value}
        placeholder="Seleziona"
        optionLabel="category"
        className="w-full md:w-14rem"
        itemTemplate={categoryItemTemplate}
        options={["Correttiva", "Integrativa", "Preventiva"]}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const categoryItemTemplate = (option) => {
    return <CustomChip text={option} />;
  };

  const statusBodyTemplate = (rowData) => {
    return <CustomChip text={"In programma"} />;
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        showClear
        value={options.value}
        placeholder="Seleziona"
        optionLabel="status"
        className="w-full md:w-14rem"
        itemTemplate={statusItemTemplate}
        options={["In programma", "In corso", "Sospesa", "Terminata"]}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const statusItemTemplate = (option) => {
    return <CustomChip text={option} />;
  };

  const formatDate = (value) => {
    return value.replaceAll("-", "/").replace("T", " ").replace("Z", "");
  };

  const dateBodyTemplate = (rowData) => {
    if (rowData.scheduled_at) {
      return formatDate(rowData.scheduled_at.slice(0, -5));
    } else {
      return "-";
    }
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key]["id"] === value);
  }

  const [editDialog, setEditDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);

  const handleDeleteDialogClick = () => {
    setDeleteDialog(!deleteDialog);
  };

  const handleEditDialogClick = () => {
    setEditDialog(!editDialog);
  };

  // Action buttons (i.e., Edit maintenance, Delete)
  const actionBodyTemplate = (rowData) => {
    return (
      <Stack direction="row" gap={2}>
        <IconButton
          size="small"
          onClick={() => {
            handleEditDialogClick();
            setSelectedRecord(rowData);
          }}
        >
          <BorderColorOutlinedIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            handleDeleteDialogClick();
            setSelectedRecord(rowData);
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Stack>
    );
  };

  // Submit delete request
  const onSubmit = (data) => {
    const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/maintenances/delete-maintenance`;

    axios
      .delete(
        API_URL,
        {
          data: { id: selectedRecord.id },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${BearerToken}`,
          },
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.success === 1) {
          // callback to maintenance index
          handleDeleteDialogClick();
          handleDelete(selectedRecord.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // dialog footer
  const DialogFooter = (
    <Stack
      gap={2}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <CustomButton
        btnIcon={null}
        btnText="Annulla"
        option="outlined-red"
        onClick={handleEditDialogClick}
      />
      <CustomButton
        btnIcon={null}
        btnText="Elimina"
        option="filled-red"
        onClick={onSubmit}
      />
    </Stack>
  );

  // Data table columns
  const columns = [
    <Column key="1" field="title" header="Titolo" sortable></Column>,
    <Column
      key="3"
      field="category"
      header="Categoria"
      filter
      body={categoryBodyTemplate}
      showFilterMatchModes={false}
      filterElement={categoryFilterTemplate}
    ></Column>,
    <Column
      key="5"
      field="priority"
      header="Priorità"
      filter
      body={priorityBodyTemplate}
      showFilterMatchModes={false}
      filterElement={priorityFilterTemplate}
    ></Column>,
    <Column
      key="6"
      field="status"
      header="Stato"
      filter
      body={statusBodyTemplate}
      showFilterMatchModes={false}
      filterElement={statusFilterTemplate}
    ></Column>,
    <Column
      key="7"
      field="scheduled_at"
      header="Programmata per"
      sortable
      body={dateBodyTemplate}
    ></Column>,
    <Column key="8" body={actionBodyTemplate} exportable={false}></Column>,
  ];

  // ##################### CSV EXPORT ###############################
  const exportExcel = () => {
    const dataNoChecklist = data?.map(
      ({
        id,
        appId,
        score,
        priority,
        scheduled_at,
        started_at,
        plant_id,
        checklist_id,
        created_at,
        updated_at,
        completed_at,
        quality,
        plantId,
        checklist,
        completeAt,
        description,
        ...rest
      }) => ({
        ...rest,
      })
    );

    const filename =
      "Manutenzioni-periodiche_" + new Date().getTime() + ".xlsx";

    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataNoChecklist);

      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Manutenzioni");

      /* fix headers */
      xlsx.utils.sheet_add_aoa(
        worksheet,
        [["Nome attività", "Categoria", "Priorità", "Stato"]],
        {
          origin: "A1",
        }
      );

      xlsx.writeFile(workbook, filename);
    });
  };

  const header = (
    <div style={{ paddingLeft: "95%" }}>
      <Tooltip title="Esporta in Excel">
        <IconButton onClick={exportExcel}>
          <ContentPasteGoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  //################################################################

  return (
    <div>
      {data && (
        <DataTable
          dataKey="id"
          scrollable
          scrollHeight="600px"
          selectionMode="single"
          filters={filters}
          // selection={selectedItem}
          // onRowSelect={onRowSelect}
          // onSelectionChange={(e) => setSelectedItem(e.value)}
          ///////////////////
          value={data}
          size="small"
          removableSort
          header={header}
          tableStyle={{
            minWidth: "50rem",
            fontSize: "0.875rem",
          }}
        >
          {columns}
        </DataTable>
      )}
      {/* DELETE ITEM DIALOG */}
      <Dialog
        modal
        footer={DialogFooter}
        visible={deleteDialog}
        style={{ width: "32rem" }}
        onHide={handleDeleteDialogClick}
        header="Conferma eliminazione"
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {<span>⚠️ Sei sicuro di voler eliminare questo elemento?</span>}
        </div>
      </Dialog>
      {/* EDIT ITEM DIALOG */}
      <EditMaintenanceForm
        toggle={editDialog}
        record={selectedRecord}
        handleEdit={handleEdit}
        setToggle={setEditDialog}
      />
    </div>
  );
}

export default PeriodicMaintenancesDataTable;
