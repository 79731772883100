import axios from "axios";
import React, { useEffect, useState } from "react";

import { Box, Stack, CircularProgress } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import LineChart from "./LineChart";
import { PlantNameIdObject as plantObj } from "./PlantNameIdObject";
import CustomButton from "../../../components/shared/CustomButton";
import CustomSelect from "../../../components/shared/CustomSelect";
import CustomMultiSelect from "../../../components/shared/CustomMultiSelect";
import LineGraph from "./LineGraph";
// import BarGraph from "./BarGraph";

function PlantsProduction() {
  // Plants cumulative production data per year / month
  const [monthlyData, setMonthlyData] = useState(null);
  const [annualData, setAnnualData] = useState(null);
  // Plants spot production data
  const [productionData, setProductionData] = useState([]);
  const BearerToken = JSON.parse(localStorage.currentUser).accessToken;
  const API_URL = `${process.env.REACT_APP_API_BASE_URL}/plants/get-plant-production`;

  const [backToTopButton, setBackToTopButton] = useState(false);

  const randomHexColorCode = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6);
  };

  async function getAPIData(selectedPlantID) {
    try {
      const response = await axios.get(`${API_URL}?id=${selectedPlantID}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BearerToken}`,
        },
      });

      // Use the functional form of setProductionData to avoid issues with stale state
      setProductionData((prevData) => [
        ...prevData,
        {
          colour: randomHexColorCode(),
          name: plantObj[selectedPlantID],
          data: response.data.results.productionDataPoints,
        },
      ]);
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  }

  const [plantNames, setPlantNames] = React.useState([]);
  const [periodicity, setPeriodicity] = React.useState(null);

  const getPlantProduction = () => {
    const plantIDs = Object.keys(plantObj).filter((key) =>
      plantNames.includes(plantObj[key])
    );

    plantIDs.forEach((plantID) => getAPIData(plantID));
  };

  const handlePlantChange = (event) => {
    setPlantNames(event.target.value);
  };

  const handlePeriodicityChange = (event) => {
    setPeriodicity(event.target.value);
  };

  useEffect(() => {
    // guarantees that plantNames will be the updated state during execution
    if (plantNames.length > 0) {
      setProductionData([]);
      getPlantProduction();
    }

    // Scroll event listener for "back to top" button
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup scroll event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [plantNames]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    return date
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "UTC",
      })
      .replace(",", " -");
  };

  // Function to prepare chart data for multiple plants
  const getChartData = () => {
    return {
      labels:
        productionData.length > 0
          ? productionData[0].data.map((dp) => formatDate(dp.date))
          : [], // Assume all plants have the same timestamps
      datasets: productionData.map((plant) => ({
        label: plant.name,
        data: plant.data.map((dp) => dp.production),
        borderColor: plant.colour,
        backgroundColor: plant.colour,
        fill: false,
      })),
    };
  };

  // graph options: default = this week
  // last month -> shows 30 ticks one for each day of the month
  // last year -> shows 12 ticks one for each month

  // format: "%b %d",
  // tickValues: "every month",

  return (
    <Box>
      <Box
        sx={{
          margin: "11px 32px",
          height: "100vh",
          borderRadius: "12px",
          border: "1px solid #e6edff",
        }}
      >
        <Box sx={{ margin: "11px 32px 0" }}>
          <Stack direction="column" alignItems="start">
            <h3>Produzione impianti</h3>
            <Stack direction="row" alignItems="center">
              <CustomMultiSelect
                label="Impianti"
                menuItems={Object.values(plantObj)}
                selection={plantNames}
                handleChange={handlePlantChange}
              />
              <CustomSelect
                label="Periodicità"
                menuItems={["Questa settimana", "Mese scorso", "Anno scorso"]}
                value={periodicity}
                handleChange={handlePeriodicityChange}
              />
            </Stack>
          </Stack>
        </Box>

        {productionData.length > 0 && <LineGraph data={getChartData()} />}
      </Box>
      {backToTopButton && (
        <CustomButton
          option=""
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          btnIcon={<KeyboardArrowUpIcon />}
        />
      )}
    </Box>
  );
}

export default PlantsProduction;
